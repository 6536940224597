.page{
  margin: 0 auto;
  width: 1400px;
}
.page .head{
  min-height: 769px;
}
.page .head img{
  width: 100%;
  vertical-align: middle;
}

.page .floor{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page .floor .title{
  width: 349px;
}
.page .floor .con{
  width: 1280px;
  margin-top: 50px;
}

.page .floor1{
  min-height: 532px;
  margin-top: -120px;
  padding-bottom: 90px;
  
}

.page .floor2{
  padding: 50px 0 100px 0;
  background-image: linear-gradient(164deg, #FEF2F6 0%, #FEFCFD 100%);
}

.page .floor3{
  padding: 36px 0 205px 0;
}

.page .floor3 .title{
  width: 494px;
}

.page .footer{
  width: 100%;
  height: 72px;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #FFFFFF;
  a{
    color: #fff;
    text-decoration: none;
  }
}



